.pricing-old-price{
    
  font-size: 1em;
  line-height: 1em;
  position: relative;
}
.pricing-old-price::after {
    border-bottom: 2px solid rgb(56, 56, 56);
    content: "";
    left: 0;
    margin-top: calc(0.125em / 2 * -1);
    position: absolute;
    right: 0;
    top: 50%;
    rotate: 170deg;
  }
.rdrInputRanges{
  display:none;
}
.items-center.w-full.bg-transparent.border-collapse tr:nth-child(even) {background: #f8fafc}
.items-center.w-full.bg-transparent.border-collapse tr:nth-child(odd) {background: #FFF}
  
  

