.div-switch {
  display: inline-flex;
  align-items: center;
}
.input-switch[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.label-switch {
  cursor: pointer;
  text-indent: -9999px;
  width: 40px;
  height: 20px;
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
}

.label-switch:after {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  background: #fff;
  border-radius: 10px;
  transition: 0.3s;
}

.input-switch:checked + .label-switch {
  background: #bada55;
}

.input-switch:checked + .label-switch:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

.label-switch:active:after {
  width: 10px;
}

.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 50px;
  height: 25px;
  background: grey;
  border-radius: 50px;
  position: relative;
  transition: background-color 0.2s;
}

.react-switch-label .react-switch-button {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 21px;
  height: 21px;
  border-radius: 21px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 25px;
}

.iyosa-input-hidden {
  position: absolute;
  left: -9999px;
}

input[type="radio"].iyosa-input-hidden:checked + label > img {
  border: 1px solid #fff;
  box-shadow: 0 0 3px 3px #090;
}

/* Stuff after this is only to make things more pretty */
input[type="radio"].iyosa-input-hidden + label > img {
  border: 1px dashed #444;
  width: 150px;
  height: 150px;
  transition: 500ms all;
  margin-left: 5px;
}
#preview{
  resize: both;
  border: 1px solid #444;
}

button.open-popup{
  display: inline-block;
  text-decoration: none;
  padding: 15px;
  background-color: #fff;
  border-radius: 3px;
  text-transform: uppercase;
  color: #585858;
  font-family: 'Roboto', sans-serif;
}

.modal {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(77, 77, 77, .7);
  transition: all .4s;
}

.modal-show {
  visibility: visible;
  opacity: 1;
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
}

.modal__content {
  border-radius: 4px;
  position: relative;
  width: 100%;
  max-width: 90%;
  background: #fff;
  padding: 1em 2em;
}

.modal__footer {
  text-align: right;
}
.modal__footer  a {
    color: #585858;
  }
  .modal__footer  i {
    color: #d02d2c;
  }

.modal__close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #585858;
  text-decoration: none;
}

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 32px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 8px;
  background: #0ea5e9;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 20px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 32px;
  animation-delay: 0;
}
.pointer {
  cursor:pointer;
}
@keyframes lds-facebook {
  0% {
    top: 0px;
    height: 32px;
  }
  50%, 100% {
    top: 16px;
    height: 16px;
  }
}
